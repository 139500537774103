<template>
    <div class="row">
        <div class="text-right col-md-12">
            <a href="https://t.me/edutalk_hcns_bot">
                <img
                    :src="publicPath + 'media/svg/dang-ky-ngay-gif.gif'"
                    class="logo-edutalk max-w"
                    alt="Edutalk Tư Vấn"
                    aria-roledescription="logo"
                >
                để nhận thông báo
            </a>
        </div>
    <div class="w-100">
      <div class="btn-add-application d-flex justify-content-end mr-0">
            <button style="background-color: #472f92;" @click="dialogRequestApplications = true, query.overtime_results = ''" class="btn btn-primary mr-3">
            <i class="fa fa-plus mr-1"></i> Thêm mới</button>
        </div>
      <div id="panel-1" class="panel mt-1">
        <div id="list-propse-application" class="panel-container show">
            <div class="panel-content">
                <div class="d-flex ml-3 style-color">
                    <div class="row header">
                        <div class="mr-2 mt-1">
                            <div>
                                <label for=""><b>Thời gian tạo</b></label><br>
                                <el-date-picker
                                    class="res-head w-100"
                                    v-model="query.month"
                                    type="month"
                                    value-format="MM-yyyy"
                                    placeholder="Chọn tháng">
                                </el-date-picker>
                            </div>
                        </div>
                        <div class="mr-2 mt-1">
                            <div>
                                <label for=""><b>Danh mục</b></label><br>
                                <div class="form-group w-100">
                                    <el-select v-model="query.category" class="res-head w-100" placeholder="Chọn danh mục" collapse-tags clearable>
                                        <el-option label="Nghỉ phép" :value="0"> </el-option>
                                        <el-option label="Tăng ca" :value="1"> </el-option>
                                        <el-option label="Xin đổi ca làm việc" :value="2"> </el-option>
                                        <el-option label="Bổ sung giờ làm việc ngoài văn phòng" :value="3"> </el-option>
                                    </el-select>
                                </div>
                            </div>
                        </div>
                        <div class="mr-2 mt-1">
                            <div>
                                <label for=""><b>Trạng thái</b></label><br>
                                <div class="form-group w-100">
                                    <el-select v-model="query.status" class="res-head w-100" placeholder="Chọn trạng thái" collapse-tags clearable>
                                        <el-option label="Đang chờ duyệt" :value="0"> </el-option>
                                        <el-option label="Đã duyệt" :value="1"> </el-option>
                                        <el-option label="Đã từ chối" :value="2"> </el-option>
                                    </el-select>
                                </div>
                            </div>
                        </div>
                        <div class="mr-2 mt-1">
                            <div>
                                <div class="form-group" @click="search">
                                <button :disabled='loadingSearch' style="background-color: #472f92;" class="btn btn-primary mr-2 mt-4">
                                    <i v-bind:class="[loadingSearch ? 'el-icon-loading' : 'el-icon-search']" class="mr-1 text-white"></i>Tìm kiếm
                                </button>
                            </div>
                        </div>

                        </div>
                    </div>
               </div>
               <div class="mt-5 example-preview table-responsive">
            <table class="table table-vertical-center  table-bordered" element-loading-text="Loading...">
            <thead>
              <tr style="background-color: #F8F8F8;">
                <th>Danh mục</th>
                <th>Lý do</th>
                <th>Trạng thái</th>
                <th>Thời gian tạo</th>
                <th>Thao tác</th>
              </tr>
            </thead>
            <tbody v-if="loadingTable">
              <tr role="row" class="b-table-busy-slot text-center">
                <td colspan="16" role="cell" class="">
                  <div class="text-danger my-2">
                    <strong>Loading...</strong>
                  </div>
                </td>
              </tr>
            </tbody>
            <tbody v-else-if="ListData?.length > 0">
              <tr v-for="(item, index) in ListData">
                <td class="style-color">
                    <div v-if="item.category == 0">Nghỉ phép</div>
                    <div v-if="item.category == 1">Tăng ca</div>
                    <div v-if="item.category == 3">Bổ sung giờ làm việc</div>
                    <div v-if="item.category == 2">Xin đổi ca làm việc</div>
                </td>
                <td class="style-color">{{ checkLength(item.reason, 30) }}</td>
                <td class="style-color">
                    <div v-if="item.status == 0">
                        <div class="circle_status d-inline-flex mr-2" style="background-color: #C0C0C0"></div>
                        <span>Đang chờ duyệt</span>
                    </div>
                    <div v-if="item.status == 1">
                        <div class="circle_status d-inline-flex mr-2" style="background-color: #009821;"></div>
                            <span>Đã duyệt</span>
                    </div>
                    <div v-if="item.status == 2">
                        <div class="circle_status d-inline-flex mr-2" style="background-color: red"></div>
                        <span>Đã từ chối</span>
                    </div>
                </td>
                <td  class="style-color">{{ item.created_at | formatDateTime }}</td>
                <td >
                    <a style="margin-left: 10px;" title="Xem chi tiết" @click="showDetail(item)" href="javascript:"
                        class="btn btn-sm ml-1 btn-icon btn-outline-success">
                        <i class="fas fa-eye"></i>
                    </a>
                    <a v-if="item.status == 0" style="margin-left: 10px;" title="Xoá yêu cầu" @click="deletedialog(item.id, item.category, item.start_date)" href="javascript:"
                        class="btn btn-sm ml-1 btn-icon btn-outline-danger">
                        <i class="fas fa-trash"></i>
                    </a>
                </td>
              </tr>
            </tbody>
            <tbody v-else>
              <tr>
                <td colspan="9" class="event-title no-data">Chưa có dữ liệu</td>
              </tr>
            </tbody>
          </table>
                    <div class="position-absolute mt-3" style="right: 40px;"> Tổng số bản ghi: <b style="color: rgb(54, 153, 255);">{{ totoalData }}</b></div>
               </div>
                    <div id="paginate" class="edu-paginate mx-auto d-flex justify-content-center">
                            <paginate v-model="page" :page-count="last_page" :page-range="3" :margin-pages="1" :click-handler="clickCallback"
                                :prev-text="'Trước'" :next-text="'Sau'" :container-class="'pagination b-pagination'" :pageLinkClass="'page-link'"
                                :next-link-class="'next-link-item'" :prev-link-class="'prev-link-item'" :prev-class="'page-link'"
                                :next-class="'page-link'" :page-class="'page-item'">
                            </paginate>
                    </div>
            </div>
             <!-- dialog Show chi tiết yêu cầu -->
            <el-dialog id="dialogShowDetails"  :title="`Xem chi tiết đơn đề xuất: ${ titleDetail }`" :visible.sync="dialogShowDetails">
                <div class="container style-color">
                    <div class="info-user pt-3 border-top">
                            <div class="row">
                                <div class="col-xxl-12 col-md-6 mt-2">
                                    <label for=""><b>Mã nhân sự</b></label>
                                    <el-input disabled :value="infoDetail?.user?.id + 100000"></el-input>
                                </div>
                                <div class="col-xxl-12 col-md-6 mt-2">
                                    <label for=""><b>Họ và tên</b></label>
                                    <el-input disabled :value="infoDetail?.user?.name"></el-input>
                                </div>
                                <div class="col-xxl-12 col-md-6 mt-2">
                                    <label for=""><b>Vị trí</b></label>
                                    <el-input disabled :value="infoDetail?.user?.profile?.account_type?.name"></el-input>
                                </div>
                                <div class="col-xxl-12 col-md-6 mt-2">
                                    <label for=""><b>Phòng ban</b></label>
                                    <el-input clearable disabled :value="infoUser?.department?.department?.name"></el-input>
                                </div>
                            </div>
                    </div>
                    <!-- nếu là danh mục nghỉ phép -->
                    <div v-if="infoDetail?.category == 0" class="info-request mt-3">
                        <div class="row">
                            <div class="col-xxl-12 col-md-6 mt-2">
                                <label for=""><b>Tổng số công nghỉ phép</b></label>
                                <el-input disabled class="w-100" :value="infoDetail?.general_leave + ' Công'"></el-input>
                            </div>
                            <div class="col-xxl-12 col-md-6 mt-2">
                                <label for=""><b>Ngày bắt đầu nghỉ phép</b></label>
                                <el-input class="w-100" disabled :value="infoDetail?.start_date | formatDate"></el-input>
                            </div>
                            <div class="col-xxl-12 col-md-6 mt-2">
                                <label for=""><b>Buổi bắt đầu nghỉ phép</b></label>
                                <el-input class="w-100" v-if="infoDetail?.start_shift_off == 1" disabled value="Ca sáng"></el-input>
                                <el-input class="w-100" v-if="infoDetail?.start_shift_off == 2" disabled value="Ca chiều"></el-input>
                                <el-input class="w-100" v-if="infoDetail?.start_shift_off == 3" disabled value="Ca tối"></el-input>
                            </div>
                            <div class="col-xxl-12 col-md-6 mt-2">
                                <label for=""><b>Ngày kết thúc nghỉ phép</b></label>
                                <el-input disabled :value="infoDetail?.end_date | formatDate"></el-input>
                            </div>
                        </div>
                        <div class="mt-3">
                                <div>
                                    <div><b>Lý do nghỉ phép</b><span class="text-danger">*</span></div>
                                    <textarea :value="infoDetail?.reason" :maxlength="500" style="width: 100%;" class=" mt-1 p-2 " rows="4"></textarea>
                                </div>
                        </div>
                        <div class="row col-xl-12 mt-3 pr-0">
                            <div class="col-md-6 p-0">
                                <div><b>Số điện thoại liên hệ khi cần</b><span class="text-danger">*</span></div>
                                <el-input class="w-100 phone_contact" disabled :value="infoDetail?.phone ? infoDetail?.phone : infoUser?.phone"></el-input>
                            </div>
                            <div class="col-md-6">
                                <div><b>Loại nghỉ phép</b><span class="text-danger">*</span></div>
                                <div class="mt-4">
                                    <el-radio v-model="infoDetail.type_leave" :label="0" disabled>Nghỉ dùng phép</el-radio>
                                    <el-radio v-model="infoDetail.type_leave" :label="1" disabled>Nghỉ không lương</el-radio>
                                </div>
                            </div>
                        </div>
                    </div>
                     <!-- nếu là danh mục tăng ca -->
                    <div v-if="infoDetail?.category == 1" class="info-request mt-3">
                        <div class=" row d-flex line-caos res-input">
                            <div class="col-xl-4">
                                <div><b>Ngày tăng ca</b></div>
                                <el-input  disabled :value="infoDetail?.start_date | formatDate"></el-input>
                            </div>
                            <div class="col-xl-4">
                                <div><span><b>Giờ bắt đầu</b></span></div>
                                <el-input  disabled :value="infoDetail?.time_checkin"></el-input>
                            </div>
                            <div class="col-xl-4">
                                <div><span><b>Giờ kết thúc</b></span></div>
                                <el-input  disabled :value="infoDetail?.time_checkout"></el-input>
                            </div>
                        </div>
                        <div class="line-caos mt-3">
                            <div>
                                <div><b>Lý do tăng ca</b></div>
                                <textarea :maxlength="500" :value="infoDetail?.reason" class="mt-1 p-2 rounded-3 w-100" placeholder="Nhập lý do tăng ca"  rows="4" style="width: 100%;"></textarea>
                            </div>
                        </div>
                        <div class="line-caos mt-3" v-if="infoDetail?.category == 0">
                            <div>
                                <div><b>Loại buổi nghỉ</b></div>
                                <textarea :maxlength="300" :value="infoDetail?.overtime_results" class="mt-1 p-2 rounded-3 w-100" placeholder="Loại buổi nghỉ"  rows="2" style="width: 100%;"></textarea>
                            </div>
                        </div>
                    </div>
                     <!-- nếu là danh mục Bổ sung giờ làm việc ngoài văn phòng -->
                    <div v-if="infoDetail?.category == 3" class="info-request mt-3">
                        <div class="row d-flex line-caos ">
                            <div class="col-xl-4">
                                <div><b>Ngày cần bổ sung</b></div>
                                <el-input  disabled :value="infoDetail?.start_date | formatDate"></el-input>
                            </div>
                            <div class="col-xl-4">
                                <div><span><b>Thời gian bắt đầu</b></span></div>
                                <el-input  disabled :value="infoDetail?.time_checkin"></el-input>
                            </div>
                            <div class="col-xl-4">
                                <div><span><b>Giờ kết thúc</b></span></div>
                                <el-input  disabled :value="infoDetail?.time_checkout"></el-input>
                            </div>
                        </div>

                        <div class="line-caos mt-3">
                            <div>
                                <div><b>Lý do bổ sung</b></div>
                                <textarea :maxlength="500" :value="infoDetail?.reason" class="mt-1 rounded-3" placeholder="Nhập lý do bổ sung"  rows="4" style="width: 100%;"></textarea>
                            </div>
                        </div>
                    </div>
                     <!-- nếu là danh mục xin đổi ca -->
                    <div v-if="infoDetail?.category == 2" class="info-request mt-3">
                        <div class="row d-flex box-info-detail">
                            <div class="mt-3 col-xl-4">
                                <div><b>Ngày đổi ca</b></div>
                                <el-input disabled :value="infoDetail?.start_date | formatDate"></el-input>
                            </div>
                            <div class=" mt-3 col-xl-4">
                                <div><b>Ca hiện tại</b></div>
                                <el-input disabled :value="changeCurrentWorking(infoDetail?.current_working_hours)"></el-input>
                            </div>
                            <div class=" mt-3 col-xl-4">
                                <div><b>Ca thay đổi</b></div>
                                <el-input disabled :value="changeCurrentWorking(infoDetail?.current_working_change)"></el-input>
                            </div>
                        </div>
                        <div class="mt-3">
                                <div>
                                    <div><b>Lý do đổi ca</b></div>
                                    <textarea placeholder="Nhập lý do đổi ca" :value="infoDetail?.reason" :maxlength="500" style="width: 100%;"  class=" mt-1 p-2 " rows="4"></textarea>
                                </div>
                        </div>
                    </div>
                    <!-- box show detail bottom -->
                    <div class="info-request-details line-caos d-flex row">
                        <div class="col-xl-3 mt-3">
                                <div>
                                    <div><b>Trạng thái đơn</b><span class="text-danger">*</span></div>
                                    <el-input class="w-100" v-if="infoDetail?.status == 0 " disabled value="Đang chờ duyệt"></el-input>
                                    <el-input class="w-100" v-if="infoDetail?.status == 1 " disabled value="Đã duyệt"></el-input>
                                    <el-input class="w-100" v-if="infoDetail?.status == 2 " disabled value="Đã từ chối"></el-input>
                                </div>
                        </div>
                        <div class="col-xl-3 mt-3" v-if="infoDetail?.user_handler && infoDetail?.status == 1">
                            <div>
                                <div><b>Người duyệt</b><span class="text-danger">*</span></div>
                                <el-input class="w-100" disabled :value="infoDetail?.user_handler?.name"></el-input>
                            </div>
                        </div>
                        <div class="col-xl-3" v-if="infoDetail?.user_handler && infoDetail?.status == 2">
                             <!-- NGƯỜI TỪ CHỐI -->
                             <div class="mr-4 line-caos mt-3">
                                    <div>
                                        <div><b>Người từ chối</b></div>
                                        <el-input class="w-100" disabled :value="infoDetail?.user_handler?.name"></el-input>
                                    </div>
                             </div>
                        </div>
                        <div class="col-xl-3 mt-3" v-if="infoDetail?.category == 1 && infoDetail?.status == 1">
                            <div>
                                <div><b>Loại tăng ca</b><span class="text-danger">*</span></div>
                                <el-input class="w-100" :value="infoDetail?.type_arise ? 'Phát sinh công việc' : 'Phát sinh giờ làm'" disabled></el-input>
                            </div>
                        </div>
                    </div>
                    <div v-if="infoDetail?.user_handler && infoDetail?.status == 2">
                            <!-- LÝ DO TỪ CHỐI -->
                            <div class="w-100 mt-3">
                                <div>
                                    <div><b>Lý do từ chối</b></div>
                                    <textarea :maxlength="500" :value="infoDetail?.reason_for_refusal" class="mt-1 p-2 rounded-3"  rows="4" style="width: 100%;"></textarea>
                                </div>
                            </div>
                    </div>
                    <div class="d-flex justify-content-end mt-4">
                        <button v-if="infoDetail?.status == 0" @click="dialogConfirmDeleteRequest = true" class="btn btn-danger">Xoá yêu cầu</button>
                    </div>
                </div>
            </el-dialog>

            <el-dialog id="dialog-confirm-delete-request"  style="z-index: 10000; margin-top: 20vh" :title="`Xoá đơn xin ${ titleDetail }`" :visible.sync="dialogConfirmDeleteRequest">
                <h4> Bạn có chắc chắn muốn xoá đơn xin {{ titleDetail }} ngày {{ infoDetail?.start_date }}?</h4>
                <div class="d-flex justify-content-end mt-4">
                    <div><el-button  @click="dialogConfirmDeleteRequest = false" class="rounded mr-3 btn-refuse">Huỷ bỏ</el-button></div>
                    <div><el-button  @click="actionDelete()" v-loading="loadingActionDelete" :disabled="loadingActionDelete" style="background-color: #472f92 ;" class="text-white rounded mr-3 btn-accept shadow-none border-0">Xác nhận</el-button></div>

                </div>
        </el-dialog>

            <!-- dialog tạo yêu cầu nghỉ phép -->
            <el-dialog id="dialog-request-applicationss" :title="`Thêm mới đề xuất: ${ titleDetail}`" :visible.sync="dialogRequestApplications">
            <div class="requestRest ml-3 style-color">
                <div class=" d-flex type-applicationss" >
                    <div class="mr-2 mt-2"><p><b>Bạn đang sử dụng mẫu:</b></p></div>
                    <div class="d-flex">
                        <el-select class="w-100" id="chooseTypes" @change="changeCategory" type="danger" v-model="query.id_type" placeholder="Mẫu đơn">
                            <el-option
                                v-for="item in typeApplications"
                                :key="item.id"
                                :label="item.type"
                                :value="item.id">
                            </el-option>
                        </el-select>
                    </div>
                </div>
                <div class="info-user">
                        <div class="row">
                            <div class="col-xxl-2 col-xl-3 col-md-6 mt-3">
                                <label for=""><b>Mã nhân sự</b></label>
                                <el-input disabled :value="infoUser?.id + 100000"></el-input>
                            </div>
                            <div class="col-xxl-2 col-xl-3 col-md-6 mt-3">
                                <label for=""><b>Họ và tên</b></label>
                                <el-input disabled :value="infoUser?.name"></el-input>
                            </div>
                            <div class="col-xxl-2 col-xl-3 col-md-6 mt-3">
                                <label for=""><b>Vị trí</b></label>
                                <el-input disabled :value="infoUser?.profile?.account_type?.name"></el-input>
                            </div>
                            <div class="col-xxl-2 col-xl-3 col-md-6 mt-3">
                                <label for=""><b>Phòng ban</b></label>
                                <el-input disabled :value="infoUser?.department?.department?.name"></el-input>
                            </div>
                        </div>
                </div>
                <div class="mt-3" v-if="query.id_type == 0 && query.id_type !== ''">
                    <div class="d-flex line-caos res-input row">
                            <div class="col-xl-3 col-md-6">
                                <div><span><b>Tổng số công nghỉ phép<span class="text-danger">*</span></b></span></div>
                                <el-input class="res-head w-100 mr-5 hideen-arrow-type-number" type="number" @change="checkGeneralLeave" v-model="query.general_leave" placeholder="Tổng số công nghỉ phép"></el-input>
                            </div>
                            <div class="col-xl-3 col-md-6">
                                <div><span><b>Ngày bắt đầu nghỉ phép<span class="text-danger">*</span></b></span></div>
                                <div>
                                    <el-date-picker
                                        :picker-options="pickerStartOptions"
                                        class="w-100"
                                        @change="checkDate"
                                        v-model="query.start_date"
                                        type="date"
                                        placeholder="Chọn ngày bắt đầu nghỉ phép">
                                    </el-date-picker>
                                </div>
                            </div>
                            <div class="col-xl-3 col-md-6">
                                <div><span><b>Buổi bắt đầu nghỉ phép<span class="text-danger">*</span></b></span></div>
                                <div>
                                    <el-select v-model="query.start_shift_off" class="w-100" placeholder="Chọn ca bắt đầu nghỉ" collapse-tags clearable>
                                        <el-option label="Ca sáng" :value="1"> </el-option>
                                        <el-option label="Ca chiều" :value="2"> </el-option>
                                        <el-option label="Ca tối" :value="3"> </el-option>
                                    </el-select>
                                </div>
                            </div>
                            <div class="col-xl-3 col-md-6">
                                <div><span><b>Ngày kết thúc nghỉ phép<span class="text-danger">*</span></b></span></div>
                                <div>
                                    <el-date-picker
                                        class="w-100"
                                        @change="checkDate"
                                        v-model="query.end_date"
                                        type="date"
                                        placeholder="Chọn ngày kết thúc nghỉ phép">
                                    </el-date-picker>
                                </div>
                        </div>
                    </div>
                    <div class="line-caos mt-3">
                        <div>
                            <div><span><b>Lý do nghỉ phép<span class="text-danger">*</span></b></span></div>
                            <el-input
                            style="width: 100%;"
                            type="textarea"
                            rows="5"
                            class="mt-1 mb-2 p-0 rounded-3"
                            placeholder="Nhập lý do nghỉ phép"
                            v-model="query.reason"
                            maxlength="500"
                            show-word-limit
                            >
                            </el-input>
                        </div>
                    </div>
                    <div class="line-caos d-flex">
                        <div class="col-md-12 d-flex p-0">
                            <div class="col-md-3 p-0">
                                <span style="word-break: break-word"><b>Loại nghỉ phép :<span class="text-danger">*</span></b></span>
                            </div>
                            <div>
                                <el-radio class="ml-1" v-model="query.type_leave" :label="0">Nghỉ dùng phép</el-radio>
                                <el-radio class="ml-1" v-model="query.type_leave" :label="1">Nghỉ không lương</el-radio>
                            </div>
    </div>
                    </div>
                    <div class="mt-3 col-xl-12 col-md-12 col-sm-6 p-0 text-red">
                        <span style="word-break: break-word" if="!query.id_type">
                            Lưu ý: Sau khi nhân sự sử dụng hết ngày phép, thời gian nghỉ sẽ được tính là nghỉ không lương.
                        </span>
                    </div>
                    <div class="line-caos d-flex p-0">
                        <div class="col-md-12 p-0">
                            <div><span><b>Số điện thoại liên hệ khi cần<span class="text-danger">*</span></b></span></div>
                            <div class="res-phone-contacts" @click="phoneFocus" @focusout="phoneContact" v-if="!editPhoneNumber"><el-input class="w-100" :value="infoUser?.phone"></el-input></div>
                            <div class="res-phone-contacts" @click="phoneFocus" @focusout="phoneContact" v-else><el-input class="phone-number w-100 phone_contact hideen-arrow-type-number" :cols="20" style="width: 30%;" placeholder="Nhập số điện thoại liên hệ khi cần" v-model="query.phone" clearable></el-input></div>

                        </div>
                    </div>
                </div>
                <div class="mt-3" v-if="query.id_type ==  1">
                    <div class="d-flex line-caos res-input row">
                        <div class="col-xl-3">
                            <div><b>Ngày tăng ca</b><span class="text-danger">*</span></div>
                                <div>
                                    <div class="search-end-time">
                                        <div class="block">
                                            <el-date-picker
                                            class="w-100"
                                            v-model="query.start_date"
                                            :picker-options="pickerStartOptions"
                                            type="date"
                                            placeholder="Chọn ngày"
                                            clearable>
                                            </el-date-picker>
                                        </div>
                                    </div>
                                </div>
                        </div>
                        <div class="col-xl-3">
                            <div><b>Giờ bắt đầu</b><span class="text-danger">*</span></div>
                                <div>
                                    <el-time-picker
                                        class="w-100"
                                        clearable
                                        v-model="query.time_checkin"
                                        @change="changeTimeCheckOut"
                                        placeholder="Chọn giờ">
                                    </el-time-picker>
                                </div>
                        </div>
                        <div class="col-xl-3">
                            <div><b>Giờ kết thúc</b><span class="text-danger">*</span></div>
                                <el-time-picker
                                    class="w-100"
                                    clearable
                                    v-model="query.time_checkout"
                                    @change="changeTimeCheckOut"
                                    placeholder="Chọn giờ">
                                </el-time-picker>
                        </div>
                    </div>
                    <div class="line-caos mt-3">
                        <div>
                            <div><b>Lý do tăng ca</b><span class="text-danger">*</span></div>
                            <el-input
                            style="width: 100%;"
                            type="textarea"
                            rows="5"
                            class="mt-1 rounded-3"
                            placeholder="Nhập lý do tăng ca"
                            v-model="query.reason"
                            maxlength="500"
                            show-word-limit
                            >
                            </el-input>
                        </div>
                    </div>
                    <div class="line-caos mt-3">
                        <div>
                            <div><b>Kết quả tăng ca</b><span class="text-danger">*</span></div>
                            <el-input maxlength="300" show-word-limit type="textarea" v-model="query.overtime_results" class="mt-1 p-0 rounded-3" placeholder="Nhập kết quả tăng ca"  rows="3" style="width: 100%"></el-input>
                        </div>
                    </div>
                    <div class="line-caos mt-3" v-if="currentUser.profile.account_type_id == 16 || this.infoUser.checkParentDepartment">
                        <div>
                            <div><b>Loại tăng ca</b><span class="text-danger">*</span></div>
                            <el-radio v-model="query.type_arise" label="0" >Phát sinh giờ làm</el-radio>
                            <el-radio v-model="query.type_arise" label="1">Phát sinh công việc</el-radio>
                        </div>
                    </div>
                </div>
                <div class="mt-3" v-if="query.id_type ==  2">
                    <div class="d-flex line-caos res-input row">
                        <div class="col-xl-3">
                                <div><span><b>Ngày đổi ca<span class="text-danger ">*</span></b></span></div>
                                <div class="mt-1">
                                        <div class="block">
                                            <el-date-picker
                                            class="w-100"
                                            v-model="query.start_date"
                                            type="date"
                                            @change=checkToday(titleDetail)
                                            placeholder="Chọn ngày đổi ca"
                                            clearable>
                                            </el-date-picker>
                                        </div>
                                </div>
                        </div>
                        <div class="col-xl-3">
                                <div><span><b>Ca hiện tại<span class="text-danger">*</span></b></span></div>
                                <div class="mt-1">
                                    <el-select @change="checkcurrentWorkingChange" class="w-100" v-model="query.current_working_hours" placeholder="Nhập ca hiện tại">
                                                <el-option
                                                v-for="item in workTime"
                                                :key="item.value"
                                                :label="item.ca"
                                                :value="item.value">
                                                </el-option>
                                    </el-select>
                                </div>
                        </div>

                        <div class="col-xl-3">
                            <div><span><b>Ca thay đổi<span class="text-danger">*</span></b></span></div>
                            <div class="mt-1">
                                <el-select @change="checkcurrentWorkingChange" class="w-100" v-model="query.current_working_change" placeholder="Nhập ca thay đổi">
                                    <el-option
                                        v-for="item in workTime"
                                        :key="item.value"
                                        :label="item.ca"
                                        :value="item.value">
                                    </el-option>
                                </el-select>
                            </div>
                        </div>
                    </div>

                    <div class="line-caos mt-3">
                        <div>
                            <div><b>Lý do đổi ca</b><span class="text-danger">*</span></div>
                            <el-input
                            style="width: 100%;"
                            type="textarea"
                            rows="5"
                            class="mt-1 mb-2 rounded-3"
                            placeholder="Nhập lý do đổi ca"
                            v-model="query.reason"
                            maxlength="500"
                            show-word-limit
                            >
                            </el-input>
                        </div>
                    </div>
                </div>
                <div class="mt-3" v-if="query.id_type ==  3">
                    <div class="d-flex line-caos res-input row">
                        <div class="col-xl-3">
                            <div><b>Ngày cần bổ sung</b><span class="text-danger">*</span></div>
                                <div>
                                    <div class="search-end-time">
                                        <div class="block">
                                            <el-date-picker
                                            class="w-100"
                                            v-model="query.start_date"
                                            :picker-options="pickerStartOptions"
                                            type="date"
                                            placeholder="Chọn ngày cần bổ sung"
                                            clearable>
                                            </el-date-picker>
                                        </div>
                                    </div>
                                </div>
                        </div>
                        <div class="col-xl-3">
                            <div><b>Thời gian bắt đầu</b><span class="text-danger">*</span></div>
                                <div>
                                    <el-time-picker
                                        clearable
                                        v-model="query.time_checkin"
                                        :picker-options="{
                                          selectableRange: '08:00:00 - 23:59:00'
                                        }"
                                        @change="changeTimeCheckOut"
                                        class="w-100"
                                        placeholder="Chọn giờ">
                                    </el-time-picker>
                            </div>
                        </div>
                        <div class="col-xl-3">
                            <div><b>Thời gian kết thúc</b><span class="text-danger"> *</span></div>
                                <el-time-picker
                                    class="w-100"
                                    clearable
                                    v-model="query.time_checkout"
                                    :picker-options="{
                                                  selectableRange: '08:00:00 - 23:59:00'
                                                }"
                                    @change="changeTimeCheckOut"
                                    placeholder="Chọn giờ">
                                </el-time-picker>
                        </div>
                    </div>
                    <div class="mt-3">
                        <div>
                            <div><b>Lý do bổ sung</b><span class="text-danger">*</span></div>
                            <!-- <textarea :maxlength="500" v-model="query.reason" class=" mt-1 rounded-3 w-100" placeholder="Nhập lý do bổ sung"  rows="4"></textarea> -->
                            <el-input
                            style="width: 100%;"
                            type="textarea"
                            rows="5"
                            class="mt-1 mb-2 rounded-3"
                            placeholder="Nhập lý do bổ sung"
                            v-model="query.reason"
                            maxlength="500"
                            show-word-limit
                            >
                            </el-input>
                        </div>
                    </div>
                </div>
                <div class="d-flex">
                    <div v-if="(query.id_type || query.id_type !== '') && query.id_type != 1" class="d-flex justify-content-end mt-4 col-md-12">
                        <el-button @click="createPropose" class="fw-bolder" :disabled=loadingCreateRequest style="color:black; font-weight: 800;" type="warning">
                            <i v-bind:class="[loadingCreateRequest ? 'el-icon-loading' : 'el-icon-search']" class="el-icon-tickets"></i> Tạo yêu cầu
                        </el-button>
                    </div>
                    <div v-else class="d-flex justify-content-end mt-4 col-md-12">
                        <el-button @click="createPropose" class="fw-bolder" :disabled=loadingCreateRequest style="color:black; font-weight: 800;" type="warning">
                            <i v-bind:class="[loadingCreateRequest ? 'el-icon-loading' : 'el-icon-search']" class="el-icon-tickets"></i> Tạo yêu cầu
                        </el-button>
                    </div>
                </div>
            </div>
            </el-dialog>
            <el-dialog
                title="Duyệt đơn xin tăng ca"
                :visible.sync="dialogConfirm"
                width="30%"
                :before-close="handleClose">
                <span>Bạn vui lòng chọn loại tăng ca để xác nhận duyệt đơn của nhân sự.</span>
                <div class="d-flex mt-4">
                    <div class="col-md-4">
                        <h4>Loại tăng ca: <span class="text-danger">*</span></h4>
                    </div>
                    <div>
                        <el-radio v-model="query.type_arise" label="0" >Phát sinh giờ làm</el-radio>
                        <el-radio v-model="query.type_arise" label="1">Phát sinh công việc</el-radio>
                    </div>
                </div>
                <div class="col-md-12">
                    <span class="text-red">{{message_type_arise}}</span>
                </div>
                <span slot="footer" class="dialog-footer d-flex justify-content-end">
                    <div><button  @click="dialogConfirm = false" class="rounded mr-3 btn-refuse  shadow-none border-0">Huỷ bỏ</button></div>
                    <div><button  @click="confirmResultOverTime" style="background-color: #472f92 ;" class="rounded mr-3 btn-accept shadow-none border-0">Xác nhận</button></div>
                </span>
            </el-dialog>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {SET_BREADCRUMB} from "@/core/services/store/breadcrumbs.module";
import {INFORMATION_MECHANISM} from "@/core/services/store/service.module";
import {GET_LIST_USER_REQUEST, DELETE_USER_REQUEST, CREATE_APPLICATION_REQUEST} from "@/core/services/store/user/users.module";
import {USER_PROFILE} from "@/core/services/store/profile.module";
import moment from "moment-timezone";
import {mapGetters} from "vuex";
export default {
    name: "list-propose-application",
    components: {
  },
    data() {
        return {
            page: 1,
            last_page: 1,
            query:
            {
                current_working_change: '',
                current_working_hours: '',
                start_shift_off: '',
                general_leave: '',
                month: '',
                id_type: 0,
                category: '',
                start_date: '',
                end_date: '',
                reason: '',
                phone: '',
                time_checkin: '',
                time_checkout: '',
                date_range: [],
                type_leave: 0,
                overtime_results: '',
                type_arise: ''
            },
            ListData: [],
            infoDetail: [],
            infoUser: [],
            titleDetail: 'Nghỉ phép',
            timeNow: new Date(),
            id_delete: '',
            totoalData: '',
            error:
            {
                timecheckRest: false,
                general_leave: false,
                dateRest: false,
                today: false

            },
            dialogRequestApplications: false,
            dialogShowDetails: false,
            dialogConfirmDeleteRequest: false,
            TimeSearch: '',
            loadingTable: false,
            loadingSearch: true,
            loadingCreateRequest: false,
            loadingActionDelete: false,
            editPhoneNumber: false,
            typeApplications:
            [
                {id: 0, type: 'Nghỉ phép'},
                {id: 1, type: 'Tăng ca'},
                {id: 2, type: 'Xin đổi ca làm việc'},
                {id: 3, type: 'Bổ sung giờ làm việc ngoài văn phòng'},
            ],
            phong_ban_xu_ly_define: {
                1: 'TTHT',
                2: 'Tài chính',
                3: 'Nhân sự',
                4: 'SPNN',
                5: 'CNTT',
                6: 'Đối tác',
                7: 'STVT',
                8: 'Đào tạo',
                9: 'Brand',
                10: 'Digital',
                11: 'Marketing',
                12: 'R&D',
                13: 'Pháp lý',
                14: 'QLHV',
                15: 'CSVC',
            },
            workTime: [
                { value: 1 , ca: 'Sáng-chiều' },
                { value: 2 , ca: 'Chiều-tối' },
                { value: 3 , ca: 'Sáng-tối' },
            ],
            fromDate: null,
            pickerStartOptions: {
                disabledDate: this.disabledStartDate
            },
            toDate: null,
            dialogConfirm: false,
            message_type_arise: '',
            publicPath: process.env.VUE_APP_BASE_URL,

        }
    },

    computed: {
        ...mapGetters(['currentUser'])
    },
    mounted() {
        this.getInfoUser();
        this.search();
        this.$store.dispatch(SET_BREADCRUMB, [
            {title: "Đề xuất", icon: 'fas fa-list'},
            {title: "Danh sách đề xuất", icon: 'fas fa-list'}
        ]);
        this.queryServer();
        let date = new Date();
        this.fromDate = new Date(date.getFullYear(), date.getMonth(), 1);
        this.toDate = new Date();
    },

    watch: {

    },

    methods: {
        changeCategory() {
            let data = this.query;
            data.current_working_change = '';
            data.current_working_hours = '';
            data.start_shift_off = '';
            data.general_leave = '';
            data.month = '';
            data.start_date = '';
            data.end_date = '';
            data.reason = '';
            data.phone = '';
            data.time_checkin = '';
            data.time_checkout = '';
            data.date_range = [];

            switch (data.id_type) {
                case 0:
                    var result = 'Nghỉ phép'
                break;
                case 1:
                    var result = 'Tăng ca'
                break;
                case 2:
                    var result = 'Xin đổi ca làm việc'
                break;
                case 3:
                    var result = 'Bổ sung giờ làm việc ngoài văn phòng';
                break;
            }
            return this.titleDetail = result;
        },

        checkLength(item, max_length) {
            if (item.length > max_length) {
                return item.substring(0, max_length) + '...';
            } else {
                return item;
            }
        },

        time_rest(startDate, endDate) {
            const startDateObj = new Date(startDate);
            const endDateObj = new Date(endDate);
            const timeDifference = endDateObj.getTime() - startDateObj.getTime();
            const daysDifference = Math.ceil(timeDifference / (1000 * 60 * 60 * 24));
            if(daysDifference == 0){
                return 1;
            }
           return daysDifference;
        },

        showDetail(item) {
            this.dialogShowDetails = true;
            this.infoDetail = item;
            this.title(item.category);
        },


        formatDate(start,end) {
            return moment(start).format('YYYY-MM-DD') + ' - '+ moment(end).format('YYYY-MM-DD');
        },

        search() {
                this.loadingTable = true;
                this.loadingSearch= true;
                this.$store.dispatch(GET_LIST_USER_REQUEST, {
                month : this.query.month,
                category: this.query.category,
                status: this.query.status,
                page: this.page,
            }).then((res) => {
                this.ListData = res.data.data;
                this.totoalData = res.data.total;
                this.last_page =  res.data.last_page;
                this.loadingTable = false;
                }).catch(() => {
            }).finally(() => {
                this.loadingTable = false;
                this.loadingSearch= false;
            });
        },
        getInfoUser() {
                this.$store.dispatch(USER_PROFILE, {
            }).then((res) => {
                  this.infoUser = res.data;
                  this.infoUser.department = this.infoUser.use_department.filter((d) => d.user_profile_account.type == 1)[0];
                }).catch(() => {
            });
        },

        checkcurrentWorkingChange() {
            let ca_hien_tai = this.query.current_working_hours;
            let ca_thay_doi = this.query.current_working_change;
            if(ca_hien_tai == ca_thay_doi) {
                this.$notify({
                    title: 'Lỗi',
                    message: 'Nhập ca thay đổi không hợp lệ',
                    type: 'warning'
                });
                this.query.current_working_change = '';
            }
        },


        checkGeneralLeave() {
            this.error.general_leave =  false;
            let value_ckeck = this.query.general_leave;
            let result = value_ckeck % 0.5
            if(result || value_ckeck <= 0) {
                this.$notify({
                    title: 'Lỗi',
                    message: ' Nhập số công nghỉ phép không hợp lệ',
                    type: 'warning'
                });
                this.error.general_leave =  true;
                this.query.general_leave = '';
            }

            if(value_ckeck > 30) {
                this.$notify({
                    title: 'Lỗi',
                    message: 'Tổng số công có thể nghỉ tối đa là 30',
                    type: 'warning'
                });
                this.error.general_leave =  true;
                this.query.general_leave = '';
            }
        },

        deletedialog(id, category, start_date) {
            this.id_delete = id;
            this.infoDetail.start_date = start_date;
            this.dialogConfirmDeleteRequest = true;
            this.title(category)
        },

        title(category) {
            switch (category) {
                case 0:
                    var result = 'Nghỉ phép';
                break;
                case 1:
                    var result = 'Tăng ca';
                break;
                case 2:
                    var result = 'Xin đổi ca làm việc';
                break;
                case 3:
                    var result = 'Bổ sung giờ làm việc ngoài văn phòng';
                break;
            }
            return this.titleDetail = result;
        },

        actionDelete() {
            this.loadingActionDelete =  true;
            let id_user = this.id_delete;
            this.$store.dispatch(DELETE_USER_REQUEST, {
                id: id_user
            }).then((res) => {
                this.$notify({
                title: 'Thành công',
                message: 'Xoá yêu cầu thành công',
                type: 'success'
                });
                this.dialogShowDetails = false;
                this.dialogConfirmDeleteRequest = false;
                this.loadingActionDelete =  false;
                this.search();
            }).catch(() => {
            });
        },

        changeTimeCheckOut() {
            this.error.timecheckRest = false;
            let time_check_in = this.query.time_checkin;
            let time_check_out = this.query.time_checkout;
            if(time_check_out && time_check_in) {
                if(time_check_out <=  time_check_in) {
                  this.error.timecheckRest = true;
                  this.$notify.warning({
                    title: 'Sai dữ liệu',
                    message: 'Nhập thời gian không hợp lệ'
                    });
                    this.query.time_checkout = '';
            }else{
                this.error.timecheckRest = false
            }
            }

        },

        phoneFocus(){
            this.editPhoneNumber = true;
        },

        phoneContact() {
            let phone =  this.query.phone;
            const phoneRegex = /^\d{10}$/;
            let checkError = !(phoneRegex.test(phone));
            if(checkError) {
                this.$notify({
                    title: 'Lỗi',
                    message: 'Số điện thoại không hợp lê',
                    type: 'warning'
                });
                this.query.phone = '';
                this.error.checkphone = true;
                this.editPhoneNumber =  false;
            }
        },

        checkToday(category) {
            let start_date = moment(this.query.start_date).format('YYYY-MM-DD');
            let today = moment().format('YYYY-MM-DD');
            if(today > start_date) {
                  this.$notify.warning({
                    title: 'Sai dữ liệu',
                    message: `"Ngày bắt đầu ${category} Không hợp lệ"`
                });
                this.query.start_date = '';
            }
        },

        checkDate() {
            let startDate =  this.query.start_date;
            let endDate =  this.query.end_date;
            const startDateObj = new Date(startDate);
            const endDateObj = new Date(endDate);
            if(!startDate || !endDate) {
                return false;
            }
            const timeDifference = endDateObj.getTime() - startDateObj.getTime();
            const daysDifference = Math.ceil(timeDifference / (1000 * 60 * 60 * 24));
            this.error.dateRest = false;
            if(endDate < startDate) {
                this.error.dateRest = true;
                  this.$notify.warning({
                    title: 'Sai dữ liệu',
                    message: 'Ngày kết thúc Không hợp lệ'
                    });
                    this.query.end_date = ''

            }

            if(daysDifference > 30) {
                this.error.dateRest = true;
                  this.$notify.warning({
                    title: 'Error',
                    message: 'Xin nghỉ phép không quá 30 ngày'
                    });
                    this.query.end_date = ''
            }
        },

        queryServer() {
            this.loading = true;
            this.$store.dispatch(INFORMATION_MECHANISM)
                .then((response) => {
                    if (response.status === 422) {
                        this.loading = false;
                        this.$message({type: 'warning', message: `${response.message}`, showClose: true});
                    } else {
                        this.loading = false;
                        this.data = response.data;
                    }
                })
        },

        createPropose() {
            if(!this.query.phone && !this.editPhoneNumber) {
                this.query.phone = this.infoUser.phone;
            };
           if(this.error.general_leave || this.error.dateRest || this.error.today ||
                 this.error.timecheckRest || !(this.query.reason) || this.error.checkphone || !(this.query.start_date)) {
                    this.$notify({
                        title: 'Warning',
                        message: 'Vui lòng nhập đủ các trường!',
                        type: 'warning'
                        });
                        this.error.checkphone = false;
                    return false;
            }
            let start_date = moment(this.query.start_date).format('YYYY-MM-DD');
            let end_date = moment(this.query.end_date).format('YYYY-MM-DD');

            let time_checkin = moment(this.query.time_checkin).format('HH:mm:ss');
            let time_checkout = moment(this.query.time_checkout).format('HH:mm:ss');
            this.loadingCreateRequest = true;
            this.$store.dispatch(CREATE_APPLICATION_REQUEST, {
                current_working_change: this.query.current_working_change,
                current_working_hours:this.query.current_working_hours,
                start_shift_off: this.query.start_shift_off,
                general_leave: this.query.general_leave,
                month: this.query.month,
                start_date,
                end_date,
                category: this.query.id_type,
                reason: this.query.reason,
                phone: this.query.phone,
                time_checkin: time_checkin,
                time_checkout: time_checkout,
                date_range: this.query.date_range,
                type_leave: this.query.type_leave,
                overtime_results: this.query.overtime_results,
                type_arise: this.query.type_arise
            }).then((res) => {
                this.changeCategory();
                this.$notify({
                    title: 'Thành công',
                    message: 'Gửi yêu cầu thành công',
                    type: 'success'
                });
                this.editPhoneNumber =  false;
                this.changeCategory();
                this.dialogRequestApplications = false;
                this.titleDetail = 'Nghỉ phép';
                this.query.id_type = 0;
                this.search();
            }).catch((e) => {
                this.$notify({
                        title: 'Warning',
                        message: e.data.message ?? 'Tạo yêu cầu thất bại',
                        type: 'warning'
                        });
            }).finally(() => {
                this.loadingCreateRequest = false;
            });
        },
        disabledStartDate(date) {
            if (this.toDate) {
                var new_date = moment(date, "DD-MM-YYYY").add(4, 'days');
                return this.toDate > new_date
            }
            return date > new Date();
        },
        clickCallback(obj) {
            this.page = obj
            this.search()
        },
        handleClose() {
            this.dialogConfirm = false;
        },
        createOverTime() {
            if (this.query.id_type == 1) {
                this.dialogConfirm = true;
                return ;
            }
        },
        confirmResultOverTime() {
            this.createPropose();
            this.message_type_arise = '';
            this.dialogConfirm = false;
        },
        changeCurrentWorking(type) {
            switch (type) {
                case 1:
                    return 'Sáng - chiều';
                case 2:
                    return 'Chiều - tối';
                case 3:
                    return 'Sáng - tối';
                default:
                  return '';
            }
        }
    }
}
</script>

<style>
    .res-phone-contacts {
        width: fit-content;
    }

    #dialog-request-applicationss #chooseTypes {
        width: 287px !important;
    }

    #dialogShowDetails,
    #dialog-request-applicationss {
        font-size: 12px;
    }

    .el-date-editor .el-input{
        width: 208px;
    }

    /* Chrome, Safari, Edge, Opera */
    .hideen-arrow-type-number input::-webkit-outer-spin-button,
    .hideen-arrow-type-number input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
    }

    /* Firefox */
    .hideen-arrow-type-number input[type=number] {
        -moz-appearance: textfield;
    }

    #list-propse-application .header .el-input__inner{
            width: 300px !important;
    }



    #dialog-request-applicationss .el-dialog {
            width: 62.9% !important;
    }

    .circle_status{
        color: rgb(5, 5, 5);
        width: 10px;
        height: 10px;
        border-radius: 50%;
    }

    .selcet-type-applicationss{
        border-color: #B87EE7;
    }

    .btn-rest-day {
        font-size: 8px;
        padding: 5px;
        margin-top: 5px;
        opacity: 0.5;
        color: black;
    }

    .line-caos{
        line-height: 34px;
    }

    #chooseTypes {
        width: 295px !important;
    }

    @media only screen and (max-width: 1990px){
        #dialog-request-applicationss .el-dialog .el-input__inner{
            width: 100%!important
        }
    }
    @media only screen and (max-width: 1441px){

        #dialog-request-applicationss .el-dialog {
            width: 67% !important;
        }

        #dialog-request-applicationss .el-dialog #chooseTypes {
            width: 100% !important;
        }

        #dialog-request-applicationss .el-dialog .el-input__inner{
                width: 100%!important
        }

          #dialog-request-applicationss .el-dialog #chooseTypes {
            width: 295px !important;
        }

    }

    @media only screen and (max-width: 1025px){
        #dialog-request-applicationss .el-dialog {
            width: 70% !important;
        }

        .res-phone-contacts {
            width: 25%;
        }

        #dialog-request-applicationss .el-dialog .el-input__inner {
            width: 100% !important;
        }

        #dialogShowDetails .box-info-detail {
            display: block !important;
        }
    }

    @media only screen and (max-width: 821px){
        #dialog-request-applicationss .el-dialog #chooseTypes {
            width: 100% !important;
        }
    }


    @media only screen and (max-width: 769px){
        #dialog-confirm-delete-request .el-dialog,
        #dialog-request-applicationss .el-dialog {
            width: 69% !important;
        }

        #dialogShowDetails .el-dialog {
            width: 69% !important;
        }

        .line-caos {
            display: block !important;
        }

        #dialogShowDetails .box-info-detail {
            display: block!important;
        }

        table th,
        table td {
        padding: 0.5rem;
        text-align: center;
        min-width: 150px;
        }

        .res-phone-contacts {
            width: 50%;
        }

    }

    @media only screen and (max-width: 541px){
        #dialog-confirm-delete-request .el-dialog,
        #dialog-request-applicationss .type-applicationss {
            display: block !important;
        }
    }

    @media only screen and (max-width: 427px){
        #dialog-request-applicationss .el-input__inner{
            width: 300px !important;
            display: block !important;
        }

        #list-propse-application .header .el-input__inner{
            width: 373px;
        }

        #dialog-request-applicationss .el-dialog {
            width: 90% !important;
        }

        table th,
        table td {
        padding: 0.5rem;
        text-align: center;
        min-width: 170px;
        }

        #list-propse-application .header {
            display: block !important;
            width: 373px;
        }

        #list-propse-application .type-applicationss {
            display: block !important;
        }

        #list-propse-application .res-head {
           width: 300px;
        }

        #dialogShowDetails .el-dialog {
            width: 95% !important;
        }

        #chooseTypes {
            width: 327px !important;
        }
    }

    @media only screen and (max-width: 431px){
        .type-applicationss {
            display: block !important
        }

        #dialog-request-applicationss .el-dialog #chooseTypes {
            width: 100%!important
        }

        #list-propse-application .header .el-input__inner{
            width: 373px  !important;
        }

        table th,
        table td {
        padding: 0.4rem;
        text-align: center;
        }
    }

    @media only screen and (max-width: 391px){
        #dialog-request-applicationss .el-dialog #chooseTypes {
            width: 100% !important;
        }
    }

    @media only screen and (max-width: 376px){
        #dialog-confirm-delete-request .el-dialog,
        #dialog-request-applicationss .el-dialog {
            width: 80% !important;
        }

        #dialog-request-applicationss .el-input__inner{
            width: 220px !important;
            display: block !important;
        }

        #list-propse-application .header .el-input__inner{
            width: 100% !important;
        }

        #dialogShowDetails .el-input__inner {
            width: 100% !important;
        }
    }


    @media only screen and (max-width: 321px){
        #list-propse-application .header .el-input__inner{
            width: 286px !important;
        }

        #dialogShowDetails .el-input__inner {
            width: 240px !important;
        }

        #dialog-request-applicationss .el-dialog {
            width: 95% !important;
        }

        #dialog-request-applicationss .el-dialog #chooseTypes {
            width: 100% !important
        }
    }

    .style-color {
        color: #454674;
    }

    .btn-css {
        background-color: #F4F5FF;
        color: #472F92;
        border-color: #B87EE7;
    }

    .btn-css:hover {
        background-color: #d0d3f4;
        color: black;
    }

    #dialogShowDetails .is-disabled input,
    #dialog-request-applicationss .is-disabled input  {
        color: #969595 !important;
    }
   .text-red {
       color: red;
   }
   .max-w {
       width: 120px;
   }
</style>


